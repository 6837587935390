import { useEffect, useState } from 'react';
import React from 'react'
import { Navbar, Container, Form } from 'react-bootstrap';
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { addProduct } from '../../redux/product/actions';
import { accordionClick } from '../../redux/accordion/actions'
import { Link, useParams } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { activeModal } from '../../redux/nuvemFiscal/actions';
import star from '../../image/star.png'
import clock from '../../image/clock.png'
import alert from '../../image/alert.png'
import { addCupom, removeCupom } from '../../redux/cupom/actions'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Home = () => {
    const [products, setProducts] = useState([]);
    const [qnty, setQnty] = useState([]);
    const [open, setOpen] = useState(false);
    const [endOrder, setEndOrder] = useState(false);
    const [funcionalityHour, setFuncionalityHour] = useState(false);
    const [total, setTotal] = useState(0);
    const [status, setStatus] = useState('success')
    const handleOpen = () => setOpen(true);
    const [openAlert, setOpenAlert] = useState(false)
    const [message, setMessage] = useState('Produto adicionado com sucesso')

    const { db } = useParams()

    const dispatch = useDispatch();
    const { currentProduct } = useSelector(rootReducer => rootReducer.productReducer);
    const { active } = useSelector(rootReducer => rootReducer.nuvemFiscalReducer);

    const handleClose = () => {
        setOpen(false)
        dispatch(activeModal({ modal: null, status: false }))
        setEndOrder(false)
        setFuncionalityHour(false)
    };

    const MINUTE_MS = 3000;

    useEffect(() => {
        dispatch(addCupom(0))
    }, [])

    useEffect(() => {
        if (active !== undefined) {
            if (active.modal == 'alerta') {
                if (active.status) {
                    setOpen(true)
                } else {
                    setOpen(false)
                }
            } else if (active.modal == 'end') {
                if (active.status) {
                    setEndOrder(true)
                } else {
                    setEndOrder(false)
                }
            } else {
                setEndOrder(false)
                setOpen(false)
            }
        }

        // }, MINUTE_MS);

        // return () => clearInterval(interval);
    }, [active])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 460,
        bgcolor: 'var(--primaryColor)',
        border: '2px solid #000',
        boxShadow: 24,
        color: 'white',
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: 10
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        bgcolor: 'var(--primaryColor)',
        color: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    };

    useState(() => {
        api
            .get('/TodosProdutos/' + db)
            .then(response => {
                setProducts(response.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        let sum = 0;
        products.map(product => {
            currentProduct.map(key => {
                if (product.id == key.id) {
                    let value = product.promotional_value == null ? product.value : product.promotional_value
                    sum += (parseFloat(value) * Number(key.quantity))
                }
            })
        })
        setTotal(sum)
    }, [currentProduct])

    const handleQntyMin = (id) => {
        const spanQnty = document.getElementById('quantyProduct' + id)
        if (Number(spanQnty.innerHTML) >= 1) {
            spanQnty.innerHTML = Number(spanQnty.innerHTML) - 1
            dispatch(addProduct({ id: id, quantity: spanQnty.innerHTML }))
        }
        setOpenAlert(true)
        setMessage('Produto removido com sucesso')
        setTimeout(function () {
            setOpenAlert(false)
        }, 3000);
    }

    const handleQntyMax = (id) => {
        const spanQnty = document.getElementById('quantyProduct' + id)
        spanQnty.innerHTML = Number(spanQnty.innerHTML) + 1
        dispatch(addProduct({ id: id, quantity: spanQnty.innerHTML }))
        setOpenAlert(true)
        setMessage('Produto adicionado com sucesso')
        setTimeout(function () {
            setOpenAlert(false)
        }, 3000);
    }

    const onAddProduct = (id) => {
        const quantyProduct = document.getElementById('quantyProduct' + id).innerHTML;
        dispatch(addProduct({ id: id, quantity: quantyProduct }))
        setOpenAlert(true)
        setTimeout(function () {
            setOpenAlert(false)
        }, 3000);

    }

    function Alerta() {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" style={{ marginBottom: 30 }}>
                        <img src={alert} width={140} />
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" style={{ fontSize: 40, fontWeight: 900, textAlign: 'center', marginBottom: 16, lineHeight: 1.2 }}>
                        AVISO
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='divModal2'>
                        Para finalizar seu pedido
                        selecione algum produto
                    </Typography>
                    <button onClick={handleClose} className='btnModal'>Fechar</button>
                </Box>
            </Modal>
        )
    }

    function AvisoFinal() {
        return (
            <Modal
                open={endOrder}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={star} />
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 40, fontWeight: 900, textAlign: 'center', marginBottom: 16, lineHeight: 1.2 }}>
                        Seu pedido foi realizado!
                        Acompanhe o status pelo seu whatsapp.
                    </Typography>
                    <button onClick={handleClose} className='btnModal'>Fechar</button>
                </Box>
            </Modal>
        )
    }

    function HorarioFuncionamento() {
        return (
            <Modal
                open={funcionalityHour}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={clock} />
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 40, fontWeight: 900, textAlign: 'center', marginBottom: 16, lineHeight: 1.2 }}>
                        Estamos fora do nosso horário de funcionamento.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='divModal2'>
                        Você pode agendar sua compra e receber assim que iniciarmos nossas atividades
                    </Typography>
                    <button onClick={handleClose} className='btnModal'>Agendar</button>
                </Box>
            </Modal>
        )
    }

    return (
        <>
            <Alerta />
            <AvisoFinal />
            <HorarioFuncionamento />
            <Stack className='alertModal' sx={openAlert ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert variant="filled" severity={status}>{message}</Alert>
            </Stack>
            <div className='orderedContainer' style={{ marginTop: 10 }}>
                {products.sort((a, b) => a.order - b.order).map(key => {
                    if (key.whatsapp == '1') {
                        let valueFormated = parseFloat(key.value.replace('R$', '').replace('.', '').replace(',', '.'))
                        let valuePromoFormated = null

                        if (key.promotional_value !== null && key.promotional_value !== '') {
                            valuePromoFormated = parseFloat(key.promotional_value.replace('R$', '').replace('.', '').replace(',', '.'))
                        }
                        console.log(key.description)
                        return (
                            <div className='divProduct'>
                                <div className='productImage'>
                                    <img src={key.imagem} className='productImg' />
                                </div>
                                <div className='productQntBtn'>
                                    <div className='divQnty'>
                                        <button className='btnQnty' onClick={() => handleQntyMin(key.id)}>-</button>
                                        <span className='spanQnty' id={'quantyProduct' + key.id}>{0}</span>
                                        <button className='btnQnty' onClick={() => handleQntyMax(key.id)}>+</button>
                                    </div>
                                    {/* <div>
                                        <button className='btnAdd' onClick={() => onAddProduct(key.id)}>Adicionar</button>
                                    </div> */}
                                </div>
                                <div className='divProductDataBtn'>
                                    <div className='productDatas'>
                                        <div className='divTitle'>
                                            <span>{key.name}</span>
                                        </div>
                                        <div className='divDescription'>
                                            <span>{key.description}</span>
                                        </div>
                                        <CurrencyInput
                                            prefix="R$"
                                            className='valueOrderedValues2'
                                            value={valuePromoFormated == null ? valueFormated : valuePromoFormated}
                                            decimalsLimit={2}
                                            decimalScale={2}
                                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                            disabled
                                        />
                                    </div>

                                </div>
                            </div>
                        )
                    }
                })}

            </div >
        </>

    )
}

export default Home