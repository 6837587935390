import { useEffect, useState } from 'react';
import React from 'react'
import { Navbar, Container, Form } from 'react-bootstrap';
import api from '../../service/api';
import { DropdownButton, ButtonGroup, Dropdown } from 'react-bootstrap'
import Badge from '@mui/material/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { accordionClick } from '../../redux/accordion/actions'
import { addCupom, removeCupom } from '../../redux/cupom/actions'
import { Link, useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import star from '../../image/star.png'
import clock from '../../image/clock.png'
import alertImg from '../../image/alert.png'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { activeModal } from '../../redux/nuvemFiscal/actions';
import Alert2 from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { FaRegCopy } from "react-icons/fa";

const Payment = () => {
    const [payment, setPayment] = React.useState([])
    const [state, setState] = React.useState({
        debito: false,
        credito: false,
        dinheiro: false,
    });
    const [radios, setRadios] = React.useState();
    const [idClient, setIdClient] = React.useState()
    const dispatch = useDispatch();
    const { currentProduct } = useSelector(rootReducer => rootReducer.productReducer);
    const { active } = useSelector(rootReducer => rootReducer.nuvemFiscalReducer)
    const { currentCupom } = useSelector(rootReducer => rootReducer.cupomReducer);

    const [endOrder, setEndOrder] = useState(false);
    const [open, setOpen] = useState(false);
    const [funcionalityHour, setFuncionalityHour] = useState(false);
    const [cupons, setCupons] = useState([])
    const [openAlert, setOpenAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [errorAlert, setErrorAlert] = useState('success')
    const [nameClient, setNameClient] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [address, setAdrress] = useState('');
    const [number, setNumber] = useState('');
    const [bairro, setBairro] = useState('');
    const [reference, setReference] = useState('')
    const [cep, setCep] = useState('');
    const [pix, setPix] = useState('');
    const [cash, setCash] = useState('')
    const [alertModal, setAlertModal] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('error');
    const [troco, setTroco] = useState(false)
    const [obstroco, setObsTroco] = useState('')


    var dbComplete = window.location.pathname;
    var dbSplit = dbComplete.split('/')
    var db = dbSplit[2]

    const handleClose = () => {
        setOpen(false)
        dispatch(activeModal({ modal: null, status: false }))
        setEndOrder(false)
        setFuncionalityHour(false)
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        height: 460,
        bgcolor: 'var(--primaryColor)',
        border: '2px solid #000',
        boxShadow: 24,
        color: 'white',
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: 10
    };

    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        bgcolor: 'var(--primaryColor)',
        color: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    };

    useEffect(() => {
        dispatch(addCupom(0))
    }, [])

    useEffect(() => {
        const name = localStorage.getItem("nameClient");
        const whatsapp = localStorage.getItem("whatsapp");
        const address = localStorage.getItem("address");
        const cep = localStorage.getItem("cep");
        const number = localStorage.getItem("number");
        const bairro = localStorage.getItem("bairro");
        const reference = localStorage.getItem("reference");

        if (name !== null) {
            setNameClient(name)
        }

        if (whatsapp !== null) {
            setWhatsapp(whatsapp)
            api.post('/PegarPeloNumero', { phone: whatsapp.replace(/[^\d]/g, ''), db: db })
                .then(response => {
                    setIdClient(response.data.id)
                })
        }

        if (address !== null) {
            setAdrress(address)
        }

        if (cep !== null) {
            setCep(cep)
        }

        if (number !== null) {
            setNumber(number)
        }

        if (bairro !== null) {
            setBairro(bairro)
        }

        if (reference !== null) {
            setReference(reference)
        }

    }, [])

    useEffect(() => {
        // const interval = setInterval(() => {
        if (active !== undefined) {
            if (active.modal == 'alerta') {
                if (active.status) {
                    setOpen(true)
                } else {
                    setOpen(false)
                }
            } else if (active.modal == 'end') {
                if (active.status) {
                    setEndOrder(true)
                } else {
                    setEndOrder(false)
                }
            } else {
                setEndOrder(false)
                setOpen(false)
            }
        }

        // }, MINUTE_MS);

        // return () => clearInterval(interval);
    }, [active])

    useEffect(() => {
        api.get('TodasFormasPagamentos/' + db)
            .then(response => {
                setPayment(response.data);
                response.data.map(key => {
                    if (key['name'].toUpperCase() == 'PIX') {
                        setPix(key['id'])
                    }
                    if (key['name'].toUpperCase() == 'DINHEIRO') {
                        setCash(key['id'])
                    }
                })
            })
    }, [])

    useEffect(() => {
        api
            .get("/TodosCupons" + '/' + db)
            .then((response) => {
                const array = [];
                response.data.map(key => {
                    if (key.whatsapp == '1') {
                        array.push(key)
                    }
                })
                setCupons(array)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    const handleCheckClient = (event) => {
        let phone = event.target.value.replace(/[^\d]/g, '').length;
        if (phone == 11) {
            api.post('/PegarPeloNumero', { phone: event.target.value.replace(/[^\d]/g, ''), db: db })
                .then(response => {
                    setIdClient(response.data.id)
                })
        }
    }

    function Alert() {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" style={{ marginBottom: 30 }}>
                        <img src={alertImg} width={140} />
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" style={{ fontSize: 40, fontWeight: 900, textAlign: 'center', marginBottom: 16, lineHeight: 1.2 }}>
                        AVISO
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='divModal2'>
                        Para finalizar seu pedido
                        selecione algum produto
                    </Typography>
                    <button onClick={handleClose} className='btnModal'>Fechar</button>
                </Box>
            </Modal>
        )
    }

    function HorarioFuncionamento() {
        return (
            <Modal
                open={funcionalityHour}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={clock} />
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 40, fontWeight: 900, textAlign: 'center', marginBottom: 16, lineHeight: 1.2 }}>
                        Estamos fora do nosso horário de funcionamento.
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} className='divModal2'>
                        Você pode agendar sua compra e receber assim que iniciarmos nossas atividades
                    </Typography>
                    <button onClick={handleClose} className='btnModal'>Agendar</button>
                </Box>
            </Modal>
        )
    }

    function AvisoFinal() {
        return (
            <Modal
                open={endOrder}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style2}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={star} />
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: 40, fontWeight: 900, textAlign: 'center', marginBottom: 16, lineHeight: 1.2 }}>
                        Seu pedido
                        foi finalizado, agradecemos
                        a sua compra.
                    </Typography>
                    <button onClick={handleClose} className='btnModal'>Fechar</button>
                </Box>
            </Modal>
        )
    }

    const { debito, credito, dinheiro } = state;
    const error = [debito, credito, dinheiro].filter((v) => v).length !== 2;

    const handleCupom = (e) => {
        cupons.map(key => {
            if (key.code == e.target.value) {
                if (key.whatsapp == 1) {
                    dispatch(addCupom(key.value))
                } else {
                    dispatch(addCupom(0))
                }
            } else {
                dispatch(addCupom(0))
            }
        })
    }

    useEffect(() => {
        if (parseFloat(currentCupom.value) !== 0) {
            setOpenAlert(true)
            setMessage('Cupom adicionado')
        } else {
            setOpenAlert(false)
            setMessage('')
        }
    }, [currentCupom])

    const checkCEP = (e) => {
        if (e.target.value.length == 9) {
            const cep = e.target.value.replace(/\D/g, '');
            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(res => res.json()).then(data => {
                    document.getElementById("addressPayment").value = data.logradouro
                    document.getElementById("bairroPayment").value = data.bairro
                })
        }
    }

    const zipCodeMask = (value) => {
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{5})(\d)/, '$1-$2')
        document.getElementById("cepPayment").value = value
    }

    const phoneMask = (value) => {
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{2})(\d)/, "($1) $2")
        value = value.replace(/(\d)(\d{4})$/, "$1-$2")

        document.getElementById("whatsappPayment").value = value
    }

    function copiarTexto() {
        let textoCopiado = ''
        payment.map(key => {
            if (key.id == Number(pix)) {
                textoCopiado = key.obs
            }
        })
        // let btnCopy = document.getElementById("btnCopy");
        // let btnCopyValue = document.getElementById("btnCopyValue");
        navigator.clipboard.writeText(textoCopiado).then(() => {
            setAlertModal(true)
            setMessageAlert('PIX copiado!')
            setTypeAlert('success')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000)
        })
    }

    const trocoHandler = e => {
        var troco = e.target.value;
        var divTroco = document.getElementById('divTroco');
        console.log(troco)
        if (troco == 'sim') {
            console.log(divTroco)
            divTroco.style.display = 'block';
        } else {
            divTroco.style.display = 'none';
        }
    }

    return (
        <>
            <Stack className='alertModal' sx={alertModal ? { display: 'block' } : { display: 'none' }} spacing={2}>
                <Alert2 variant="filled" severity={typeAlert}>{messageAlert}</Alert2>
            </Stack>
            <Alert />
            <AvisoFinal />
            <HorarioFuncionamento />
            <div className='orderedContainer'>
                <Form className="d-flex form">
                    <span className='titlePayment'>INFORMAÇÕES</span>
                    <input className='inputPayment' id="namePayment" defaultValue={nameClient} placeholder='SEU NOME' type='text' />
                    <input required className='inputPayment' maxlength={15} defaultValue={whatsapp} placeholder='SEU WHATSAPP' id="whatsappPayment" type='text' name="celularPhysic" onChange={e => {
                        handleCheckClient(e)
                        phoneMask(e.target.value)
                    }} />
                    <span className='titlePayment'>ENTREGA</span>
                    <input className='inputPayment' maxlength={9} id="cepPayment" defaultValue={cep} placeholder='CEP' type='text' onChange={e => {
                        checkCEP(e)
                        zipCodeMask(e.target.value)
                    }} />
                    <input className='inputPayment' id="addressPayment" defaultValue={address} placeholder='ENDEREÇO' type='text' />
                    <input className='inputPayment' id="numberPayment" defaultValue={number} placeholder='NÚMERO' type='text' />
                    <input className='inputPayment' id="bairroPayment" defaultValue={bairro} placeholder='BAIRRO' type='text' />
                    <input className='inputPayment' id="referencePayment" defaultValue={reference} placeholder='REFERÊNCIA' type='text' />
                    {cupons.length > 0 ? (
                        <>
                            <input className='inputPayment' id="cupomPayment" placeholder='CUPOM' type='text' onChange={handleCupom} />
                            <span style={openAlert ? { display: 'block', color: 'green' } : { display: 'none' }}>{message}</span>
                        </>
                    ) : ('')}

                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label" className='titlePayment'>PAGAMENTO</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            onChange={(e) => setRadios(e.target.value)}
                        >
                            {payment.map(key => {
                                // if (key.obs !== 'Entrega') {
                                //     if (key.name !== 'Pix') {
                                return (
                                    <>
                                        <FormControlLabel value={key.id} className='formControlPayment' control={<Radio className='radioPayment' />} label={key.name.toUpperCase()} />
                                        <div className='divTextWhatsapp' style={pix == radios && key.id == Number(pix) ? { display: 'flex' } : { display: 'none' }}>
                                            <input className='inputTextWhatsapp' id='inputTextWhatsapp' defaultValue={key.obs} disabled />
                                            <div className='btnCopyLink' onClick={copiarTexto}>
                                                <FaRegCopy className='icoCopyLink' />
                                            </div>
                                        </div>
                                        <div style={cash == radios && key.id == Number(cash) ? { display: 'block' } : { display: 'none' }}>
                                            <div>
                                                <label className="labelForm">Vai precisar de troco?</label>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    name="radio-buttons-group"
                                                    className="radioClient"
                                                    required
                                                    style={{ display: 'flex', flexDirection: 'row' }}
                                                >
                                                    <FormControlLabel value="sim" control={<Radio onClick={() => {
                                                        setTroco(true)
                                                        setTimeout(() => {
                                                            window.scrollTo(0, 100)
                                                        }, 100)
                                                    }} />} name="radioTroco" label="Sim" />
                                                    <FormControlLabel value="nao" control={<Radio onClick={() => setTroco(false)} />} name="radioTroco" label="Não" />
                                                </RadioGroup>
                                            </div>
                                            <div className="divTroco" id="divTroco" style={troco ? { display: 'block' } : { display: 'none' }}>
                                                <label className="labelForm" >Troco para</label>
                                                <Form.Control
                                                    placeholder="Troco para"
                                                    aria-label="troco"
                                                    name="obsTroco"
                                                    onChange={e => setObsTroco(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                                //     }
                                // }
                            })}
                        </RadioGroup>
                    </FormControl>
                    <input type='hidden' id="radioPayment" value={String(radios)} />
                    <input type='hidden' id="idClientPayment" value={String(idClient)} />
                    <input type='hidden' id="obsTroco" value={obstroco} />
                </Form>
            </div >
        </>

    )
}

export default Payment